// mix.js
export default {
    data() {
        return {}
    },
    methods: {
        routerTo(path) {
            if (this.$route.path.indexOf(path) === -1) {
                this.$router.push(path)
            }
        }
    }
}
