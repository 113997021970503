<template>
  <span class="symbol">{{ symbol || currencySymbol || '$' }}</span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    symbol: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['currencySymbol',]),
  },
  directives: {

  },
  components: {

  },
  data() {
    return {


    };
  }
};
</script>

<style lang="scss" scoped>
  .symbol {

  }

</style>
