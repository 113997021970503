<template>
  <div ref="root" :style="rootStyle" class="affix">
    <div :class="{ fixed: state.fixed }" :style="affixStyle">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { getScrollContainer, off, on } from 'element-ui/src/utils/dom.js';
import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event.js';
export default {
  name: 'common-affix',
  props: {
    zIndex: {
      type: Number,
      default: 100
    },
    target: {
      type: String,
      default: ''
    },
    scrollTarget: {
      type: String,
      default: ''
    },
    offset: {
      type: Number,
      default: 0
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  data() {
    return {
      targetELe: null,
      scrollContainerEle: null,
      state: {
        fixed: false,
        height: 0, // height of root
        width: 0, // width of root
        scrollTop: 0, // scrollTop of documentElement
        clientHeight: 0, // clientHeight of documentElement
        transform: 0
      }
    };
  },
  computed: {
    rootStyle: function () {
      return {
        height: this.state.fixed ? `${this.state.height}px` : '',
        width: this.state.fixed ? `${this.state.width}px` : ''
      };
    },
    affixStyle: function () {
      if (!this.state.fixed) {
        return;
      }
      const offset = this.offset ? `${this.offset}px` : 0;
      const transform = this.state.transform ? `translateY(${this.state.transform}px)` : '';

      return {
        height: `${this.state.height}px`,
        width: `${this.state.width}px`,
        top: this.position === 'top' ? offset : '',
        bottom: this.position === 'bottom' ? offset : '',
        transform: transform,
        zIndex: this.zIndex
      };
    }
  },
  mounted() {
    if (this.target) {
      this.targetELe = document.querySelector(this.target);
      if (!this.targetELe) {
        throw new Error(`target is not existed: ${this.target}`);
      }
    } else {
      this.targetELe = document.documentElement;
    }


    if (this.scrollTarget) {
      this.scrollContainerEle = document.querySelector(this.scrollTarget);
    } else {
      this.scrollContainerEle = getScrollContainer(this.$refs.root);
    }


  },
  destroyed() {
    off(this.scrollContainerEle, 'scroll', this.onScroll);
    removeResizeListener(this.$refs.root, this.updateState);
  },
  methods: {
    bindListener() {

      this.$nextTick(() => {
        on(this.scrollContainerEle, 'scroll', this.onScroll);
        addResizeListener(this.$refs.root, this.updateState);
      })

    },

    onScroll() {
      //console.log('onScroll');
      this.updateState();
    },
    updateState() {
      if (!this.$refs.root) {
        return;
      }
      const rootRect = this.$refs.root.getBoundingClientRect();
      const targetRect = this.targetELe.getBoundingClientRect();

      this.state.height = rootRect.height;
      this.state.width = rootRect.width;
      this.state.scrollTop = this.scrollContainerEle === window ? document.documentElement.scrollTop : this.scrollContainerEle.scrollTop;
      this.state.clientHeight = document.documentElement.clientHeight;
      if (this.position === 'top') {
        if (this.target) {
          const difference = targetRect.bottom - this.offset - this.state.height;
          this.state.fixed = this.offset > rootRect.top && targetRect.bottom > 0;

          //console.log(targetRect.bottom, this.state.height);

          this.state.transform = difference < 0 ? difference : 0;
        } else {
          this.state.fixed = this.offset > rootRect.top;
        }
      } else {
        if (this.target) {
          const difference = this.state.clientHeight - targetRect.top - this.offset - this.state.height;
          this.state.fixed = this.state.clientHeight - this.offset < rootRect.bottom && this.state.clientHeight > targetRect.top;
          this.state.transform = difference < 0 ? -difference : 0;
        } else {
          this.state.fixed = this.state.clientHeight - this.offset < rootRect.bottom;
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.fixed {
  position: fixed;
}
</style>
