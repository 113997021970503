<template>
  <div class="img-swiper">
    <swiper :options="swiperOptions" >
      <swiper-slide v-for="(item, index) in data" :key="index">
<!--        <el-image class="img" :src="item" alt="" lazy></el-image>-->
<!--        <img class="img" :src="item" alt="" />-->
        <img class="img" alt="" v-lazy-load="item"/>
      </swiper-slide>


      <div class="swiper-pagination" slot="pagination"></div>
      <!-- 如果你需要导航按钮 -->
      <div class="swiper-button-prev" slot="button-prev" @click.stop></div>
      <div class="swiper-button-next" slot="button-next" @click.stop></div>
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

// Import Swiper styles
import 'swiper/css/swiper.css';

const loadimage = require('@/assets/pic/logo.png')

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
  directives: {

  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      defaultImg: loadimage,
      // Swiper options
      swiperOptions: {
        // Optional parameters
        loop: true,
        loopAdditionalSlides: -1,

          // autoplay: {
        //   delay: 2500,
        //   disableOnInteraction: false
        // },

        // Effects
        // effect: 'cube',
        // Navigation buttons
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // Pagination
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type:'progressbar',
          // progressbarFillClass : 'my-pagination-progressbar-fill',
        }
      },
    };
  }
};
</script>

<style>
  :root {
    --swiper-theme-color: #000000;
    --swiper-navigation-size: 16px;
    --swiper-navigation-color: var(--swiper-theme-color);
  }
</style>

<style lang="scss" scoped>



.img-swiper {

  &:hover {

    .swiper-button-prev, .swiper-button-next {
      opacity: 1;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    transition: opacity .3s linear;
  }

  ::v-deep .swiper-container-horizontal > .swiper-pagination-progressbar,
  ::v-deep .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    top: initial;
    bottom: 0;
    height: 1px;
  }

  ::v-deep .swiper-slide {
    position: relative;
    padding-top: 100%;
    height: initial;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      //height: 600px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

    }
  }
}









</style>
