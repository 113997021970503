<template>
  <div
    class="site-header-container site-header-container--sticky"
    data-site-header-container
  >
    <header class="site-header site-header--sticky">
      <div class="site-header__wrapper">
        <h1 class="site-header__heading">
          <router-link tag="a" to="/home" class="site-header__logo">
            <!--<img src="@/assets/icon/light-logo-extended_125x25.png" alt="" style="object-fit: cover; object-position: 50% 50%;">-->
            <img
              src="@/assets/icon/logo.png"
              alt=""
              style="object-fit: cover; object-position: 50% 50%"
            />
          </router-link>
        </h1>

        <!--<nav class="site-navigation" aria-label="Main navigation">
          <ul class="navigation-desktop">
            <li class="navigation-desktop__tier-1-item">
              <router-link
                tag="a"
                class="navigation-desktop__tier-1-link"
                to="/home"
                >Home</router-link
              >
            </li>
            <li class="navigation-desktop__tier-1-item" v-catalog-menu-hover>
              <details
                class="navigation-desktop__tier-1-details"
                data-nav-desktop-details
              >
                <summary class="navigation-desktop__tier-1-link">
                  Catalog
                  <img
                    class="icon-chevron-small"
                    src="@/assets/icon/arrow.svg"
                    alt=""
                  />
                </summary>
                <div class="navigation-meganav" data-navigation-meganav>
                  <ul class="navigation-meganav__grid" :class="menuClassName">
                    <li
                      class="navigation-meganav__tier-2-item"
                      v-for="item in menuList"
                      :key="item.id"
                    >
                      <span class="navigation-meganav__tier-2-link">
                        {{ item.name }}
                      </span>
                      <ul class="navigation-meganav__tier-3">
                        <li
                          class="navigation-meganav__tier-3-item"
                          v-for="child in item.children"
                          :key="child.id"
                        >
                          <a
                            @click="viewDetail(child)"
                            href="javascript:void(0)"
                            class="navigation-meganav__tier-3-link"
                            >{{ child.name }}</a
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </details>
            </li>
            <li class="navigation-desktop__tier-1-item">
              <router-link
                tag="a"
                class="navigation-desktop__tier-1-link"
                to="/about-us-more"
                >About</router-link
              >
            </li>
            <li class="navigation-desktop__tier-1-item">
              <router-link
                tag="a"
                class="navigation-desktop__tier-1-link"
                to="/blog"
                >Blog</router-link
              >
            </li>
            <li class="navigation-desktop__tier-1-item">
              <router-link
                tag="a"
                class="navigation-desktop__tier-1-link"
                to="/contact"
                >Contact</router-link
              >
            </li>
          </ul>
        </nav>-->
      </div>
    </header>
  </div>
</template>
<script>
import service from '@/utils/request'

export default {
  name: 'NavBar',
  data() {
    return {
      menuList: [],
    }
  },
  created() {
    this.getMenuList()
  },
  computed: {
    menuClassName() {
      let length = this.menuList.length
      return `navigation-meganav__grid--${length}-items`
    },
  },
  methods: {
    getMenuList() {
      service({
        url: '/category/list',
        method: 'get',
      }).then((res) => {
        this.menuList = res.data
      })
    },
    viewDetail(item) {
      this.$router
        .push({
          name: 'ProductList',
          query: {
            type: 'category',
            categoryId: item.id,
            categoryName: item.name,
          },
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped>

/* @media screen and (min-width: 960px) {
  .site-header__heading {
    padding: .5em 0 .3em 0;
  }
}
@media screen and (max-width: 960px){
.site-header-container{
  position: absolute !important;
    top: 7px !important;
    left: 0;
    border: 0;
    right: 0;
    margin: auto;
}
.site-header__heading {
    padding:.1em 0 .1em 0;
  }
} */

.site-header {
  height: 88px;
  display: flex;
  align-items: center;
}


/* top logo&menu box and search box */
.site-header-container {
  position: relative;
  z-index: 900;
  background-color: #fff;
}
.site-header-container.site-header-container--sticky {
  position: sticky;
  top: calc((var(--header-logo-height-mobile) + 0.375rem) * -1)
}
.site-header__wrapper {
  position: relative;
  /*border-bottom: 1px solid #dfe3ea;*/
}
.site-header__heading {
  width: var(--header-logo-width-mobile, auto);
  margin: 0 auto;
  padding: 2rem 0;
}
.site-header--sticky .site-header__heading {
  opacity: var(--scroll-away-opacity, 1);
}
.site-header__logo {
  display: block;
}
.site-header__logo img {
  display: block;
  width: 100%;
}

.site-navigation {
  position: relative;
  display: none;
  padding: 0;
  opacity: 1;
  transition: opacity .2s ease-out;
}
.site-header--sticky .site-navigation {
  padding: 0 7rem;
}


@media screen and (max-width: 576px) {
  .site-header {
   display: none;
  }
}
</style>
