<template>
  <div class="columns-swiper">
    <ul class="columns-img-list">

      <li class="columns-item" v-for="(item, index) in data" :key="index">

        <el-image class="columns-img" :src="item.big" lazy @click="clickSmallImage(item.big, index, data)"></el-image>
<!--        <img class="columns-img" :src="item.big" alt="">-->
      </li>
    </ul>

    <el-image-viewer v-if="viewImgVisible" :on-close="closeImgViewer" :url-list="viewImgList" :initialIndex="viewIndex" :zIndex="5000"/>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
  components: {
  },
  data() {
    return {
      viewImgVisible: false,
      viewImgList: [],
      viewIndex: 0,
    };
  },
  methods: {

    closeImgViewer() {
      this.viewImgVisible = false;
    },
    clickSmallImage(imgUrl, index, list) {
      this.viewImgList = list.map(item => item.big);
      this.viewIndex = index;
      this.viewImgVisible = true

    },
  }
};
</script>

<
<style lang="scss" scoped>

  .columns-swiper {
    user-select: none;
    position: relative;
  }

  .columns-img-list {

    .columns-item {
      position: relative;
      padding-top: 100%;
      height: initial;
    }

    .columns-img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;

      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;


    }

  }
</style>
