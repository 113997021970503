<comment># Upload Image Component</comment>

<template>
  <div class="base-upload">
    <el-upload
      :action="actionUrl"
      :with-credentials="true"
      list-type="picture-card"
      :limit="5"
      :accept="accept"
      :file-list="fileList"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :before-upload="beforeUpload"
      :http-request="uploadImage"
      :on-success="handleSuccess"
    >
      <i class="el-icon-plus"></i>
    </el-upload>

    <el-image-viewer v-if="dialogVisible" :on-close="closeImgViewer" :url-list="viewImgList" :zIndex="5000" :initialIndex="0"/>
  </div>
</template>

<script>
  import { uploadImages } from '@/api/common'
  import elImageViewer from 'element-ui/packages/image/src/image-viewer'

  export default {
    name: 'index',
    data () {
      return {
        fileList: [],

        accept: '.jpg,.png,.jpeg',
        fileSizeLimit: 100,
        actionUrl: '',
        dialogVisible: false,
        viewImgList: [],
      }
    },
    props: {
      list: {
        type: Array,
        default: () => []
      }
    },
    components: {
      elImageViewer,
    },
    computed: {},
    watch: {},
    created () {
      this.actionUrl = this.$baseUrl + '/upload/image'
    },
    mounted () {},
    beforeDestroyed () {},
    destroyed () {},
    methods: {
      checkFileType(file, accept) {
        let types = accept.split(',').map((item) => item.split('.').pop().toLowerCase());
        let type = file.name.split('.').pop().toLowerCase(); // 获取文件后缀
        return types.indexOf(type) !== -1;
      },
      // 附件上传前
      beforeUpload(file) {
        this.$emit('on-before-upload', file);

        // 上传前 附件检测
        if (this.fileSizeLimit > 0 && file.size > this.fileSizeLimit * 1024 * 1024) {
          this.$message({
            message: 'The size of each individual file must not exceed ' + this.fileSizeLimit + 'M',
            type: 'warning'
          });
          return false;
        }

        // 类型检测
        if (!this.checkFileType(file, this.accept)) {
          this.$message({ message: 'Unsupported file type.', type: 'warning' });
          return false;
        }

        let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
        if (fileName.length > 550) {
          this.$message({ type: 'error', message: 'The file name cannot exceed 550 characters.' });
          return false;
        }

        let containSpecial = /[~!@#$%^&*]+/;
        if (containSpecial.test(fileName)) {
          this.$message({ type: 'error', message: 'The file name cannot contain special characters such as ~!@#$%^&*' });
          return false;
        }

        return true;
      },
      uploadImage (params) {

        const file = params.file
        // 使用FormData传参数和文件
        var form = new FormData()
        // 文件
        form.append('image', file)
        // 参数
        form.append('id', '')

        // 调用封装好的上传方法，传给后台FormData
        uploadImages(form).then(resp => {
          if (resp && resp.code == 200) {
            this.$message('Success Upload')

            //console.log('handleSuccess', file, this.fileList)

            file.imgUrl = resp.data.url;
            debugger;

            let newImg = {
              name: file.name,
              uid: file.uid,
              fileUrl: resp.data.url
            }
            let list = [...this.list, newImg ]
            this.$emit('update:list', list);
            this.$emit('success', resp.data);
          }
        })

      },
      handleRemove (file, fileList) {
        //console.log('handleRemove', file, fileList)

        let list = this.list.filter(item => item.uid !== file.uid);
        this.$emit('update:list', list);
      },
      handleSuccess (file, fileList) {
        //console.log('handleSuccess', file, fileList)
      },
      handlePictureCardPreview (file) {
        this.viewImgList = [file.url]
        this.dialogVisible = true
      },
      closeImgViewer () {
        this.dialogVisible = false
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>
