<template>
  <nav class="header-controls">
    <div class="header-controls__wrapper">
      <div class="header-controls__mobile-trigger">
        <button
          class="header-controls__mobile-button"
          aria-label="Menu"
          @click="openMobileNav"
        >
          <img src="@/assets/icon/mobile-nav.svg" alt=""/>
        </button>
      </div>

      <div class="header-controls__search">
        <button
          class="header-controls__search-button"
          type="button"
          aria-label="Search"
          @click="openGlobalSearch"
        >
          <img src="@/assets/icon/search-icon.svg" alt=""/>
          <span class="header-controls__search-button-label">Search</span>
        </button>
      </div>

      <!-- LOGO -->
      <div class="logoIcon">
        <router-link tag="a" to="/home" class="site-header__logo">
          <!--<img src="@/assets/icon/light-logo-extended_125x25.png" alt="" style="object-fit: cover; object-position: 50% 50%;">-->
          <img class="logo-icon" src="@/assets/icon/logo.png" alt="" style="object-fit: cover; object-position: 50% 50%"/>
        </router-link>
      </div>
      <div class="header-controls__account-cart">

        <div class="head-select">
          <el-select size="mini" v-model="Currencies" @change="changeCurrencies">
            <el-option
              v-for="item in allCurrencies || []"
              :key="item.value"
              :value="item.name">{{ item.name }} : {{item.value}}
            </el-option>
          </el-select>
        </div>


        <a
          @click="viewAccount()"
          href="javascript:void(0);"
          class="header-controls__account"
        >
          <img src="@/assets/icon/user-icon.svg" alt=""/>
        </a>
        <a
          href="javascript:void(0);"
          class="header-controls__cart"
          @click="openCart()"
        >
          <img src="@/assets/icon/bag-icon.svg" alt=""/>
          <!-- <span class="visually-hidden">Cart</span> -->
          <span class="header-controls__cart-count">{{
            cartCount ? `(${cartCount})` : ''
          }}</span>
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
  import service from '@/utils/request'
  import { isLogin } from '@/utils/util'
  import { mapGetters } from 'vuex'
  import { setCurrency } from '@/api/common'

  export default {
    name: 'HeaderBar',
    data () {
      return {
        isLogin: false,
        cartCount: 0,
        Currencies: localStorage.getItem('currencyCode') || "USD",
        currencySymbol: localStorage.getItem('currencySymbol') || "$",
      }
    },
    created () {
      this.getCartCount()
      this.isLogin = isLogin()
    },
    mounted() {

      this.scrollBarListen()
    },
    computed: {
      ...mapGetters(['hasNewCartTip', 'allCurrencies', 'currencyCode']),
    },
    watch: {
      $route: {
        handler(val) {
          this.isLogin = isLogin();
        },
      },
      hasNewCartTip (val, oldVal) {
        if (oldVal === false && val) {
          this.$store.dispatch('cart/setHasNewTip', false)
          this.getCartCount()
          this.openCart()
        }
      },

      currencyCode (val, oldVal) {
        if (val) {
          this.Currencies = val;
        }
      },
    },
    methods: {
      changeCurrencies(val) {
        setCurrency({ currency: val }).then(res => {
          // this.$notify({
          //   title: 'Success',
          //   message: 'Operation success!',
          //   type: 'success',
          // })
          if (res.code === 200) {
            this.$store.commit('user/SET_currencyCode', val)
            let currencySymbol = this.allCurrencies.find(item => item.name === val).value;
            this.$store.commit('user/SET_currencySymbol', currencySymbol);
            location.reload();
          }
        })
      },
      scrollBarListen() {

        $(document).scroll(function() {
          let scroH = $(document).scrollTop();
          let nums = scroH/400;
          $(".header-controls").css("background",'rgba(255,255,255,' + nums + ')');
          // $(".header-controls .iconfont").css("background",'rgba(102, 102, 102,'+(1 - nums)+')');
          // $(".header-controls .iconfont").css("color",'rgb('+(255 - ((255 - 102)/2)*nums)+', '+(255 - ((255 - 102)/2)*nums)+', '+(255 - ((255 - 102)/2)*nums)+')');
        });
      },
      openMobileNav () {
        this.$emit('openMobileNav')
      },
      openGlobalSearch () {
        this.$emit('openGlobalSearch')
      },
      openCart () {
        this.$emit('openCart')
      },
      getCartCount () {
        service({
          url: '/car/count',
          method: 'get',
        }).then((res) => {
          this.cartCount = res.data.count
        })
      },
      viewAccount () {
        this.$router.push('/account').catch(() => {})
        // if (isLogin()) {
        //   this.$router.push('/account').catch(() => {})
        // } else {
        //   this.$router.push('/login').catch(() => {})
        // }
      },
    },
  }
</script>
<style scoped lang="scss">

  .head-select {
    width: 80px;

    ::v-deep .el-select {

      input {
        font-size: 14px;
        background-color: transparent;
        border-color: transparent!important;

      }

      .el-input__icon {
        color: #333333;
      }
    }

  }

  /* top header box */
  .header-controls {
    position: fixed;
    top: 0px;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    z-index: 901;
    padding: 0 2vw;
    /*background-color: #ffffff;*/
    background-color: rgba(255,255,255,0)
  }

  .header-controls__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 88px;
    box-sizing: border-box;
  }

  .header-controls__mobile-trigger {

  }

  .header-controls__mobile-button {
    display: block;
    background: transparent;
    border: none;
    padding: 0.5rem;
    color: #131416;
    cursor: pointer;
  }

  .header-controls__search-button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem;
    color: #131416;
    cursor: pointer;
    background: none;
    border: none;
  }

  .header-controls__search-button-label {
    display: none;
  }

  .header-controls__account-cart {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: flex-end;
    font-size: 0;
  }

  .header-controls__account,
  .header-controls__cart {
    display: inline-block;
    margin: -0.5rem -0.5rem -0.5rem 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    margin-left: 0;
  }

  .header-controls__cart-count {
    font-size: 0.875rem;
    margin: 0 0 0 0.5rem;

  }

  .header-controls__account span {
    vertical-align: middle;
  }

  @media screen and (min-width: 960px) {

    /* layout style */

    /* top header  */
    .header-controls__mobile-trigger {
      /*display: none;*/
    }
    .header-controls__search {
      /*position: absolute;*/
    }
    .header-controls__search-button-label {
      display: block;
    }
    .header-controls__account-cart {
      position: absolute;
      right: 0;
    }

  }


  .logoIcon {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    .logo-icon {
      width: 180px;
    }
  }


  @media screen and (min-width: 576px) {

  }

  @media screen and (max-width: 576px) {
    .head-select {
      /*display: none;*/
      width: 55px;

      ::v-deep .el-select{

        input {
          padding: 5px 0;
          text-align: center;
        }

        .el-input__suffix {
          /*display: none;*/
          position: absolute;
          right: -8px;

          .el-input__icon {
            font-size: 12px;
          }
        }
      }
    }

    .header-controls {
      margin: 0 !important;

      .header-controls__wrapper {
      }

      .logoIcon .logo-icon {
        width: 6rem;
      }
    }
  }
</style>
