
import request from '@/utils/request'

export const uploadImages = (data) => {

  return request({
    url: 'upload/image',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data,
  })
}

export const uploadImageBase64 = (data) => {

  return request({
    url: 'upload/imageBase64',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data,
  })
}

export const oauthLogin = (data) => {

  return request({
    url: '/sso/oauthLogin',
    method: 'post',
    data,
  })
}

export const setCurrency = (data) => {

  return request({
    url: '/open/setCurrency',
    method: 'put',
    params: {
      ...data,
    },
  })
}
