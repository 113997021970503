<template>
  <el-drawer
    :with-header="false"
    :visible.sync="drawer"
    :size="cartBlockWidth"
    direction="rtl">
    <div class="offcanvas offcanvas-end cart-offcanvas cart-offcanvas-container" tabindex="-1" id="cartOffcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div class="cart-offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">CART</h5>
        <span @click="drawer = false" style="cursor: pointer">
          <i class="el-icon-close"></i>
        </span>
      </div>
      <div class="cart-offcanvas-body">
        <div id="cartNoData" v-if="cartNumber === 0">
          YOUR CART IS EMPTY
        </div>
        <div id="cartItemWrapper" v-else>
          <div class="cart-item" v-for="item in cartInfo.list" :key="item.id">
            
            <el-row :gutter="20">
              <el-col :span="10">
                <div
                  style="background: #fff;"
                  v-if="item.corner">
                  <span class="corner" :style="{ 'background-color': item.cornerColor }">
                      {{ item.corner }}
                  </span>
                </div>
                <router-link
                    class="cart-item-link"
                    tag="a"
                    :to="{name: 'ProductDetail', query: {type: 'product', productId: item.productId, productName: item.productName, count: item.productCount, skuValue: item.skuValue}}">
                  <img :src="item.productCoverImg" alt="" @click="drawer = false">
                </router-link>
              </el-col>
              <el-col :span="14">
                <p class="cart-item-title">{{item.productName}}</p>
                <p class="cart-item-price" :style="{'color':'#de6d5c'}"><CurrencySymbol :symbol="item.symbol"/>{{item.productPrice | moneyFormat}}</p>
                <p class="cart-item-attr" :style="{'color': item.skuNameColor || '#000'}">{{item.skuName || ''}}</p>
                <p class="cart-item-quantity-actions">
                  <el-input-number size="mini" v-model="item.productCount" :min="1" @change="quantityChange(item)"></el-input-number>
                  <span class="cart-item-remove" @click="removeOrderItem(item.id)">Remove</span>
                </p>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <footer class="cart-offcanvas-footer">
        <p v-if="this.shippingFree && this.shippingFree > 0">Free shipping on orders over <span style="color: red;"><CurrencySymbol :symbol="this.symbol"/>{{this.shippingFree | moneyFormat}}</span></p>
        <p v-else style="font-size:16px;">Free shipping and tax exemption</p>
        <button id="checkoutCart" :disabled="cartNumber === 0" @click="checkout()">
          CHECKOUT
          <span id="cartTotalPrice" style="margin-left: 10px;"><CurrencySymbol :symbol="this.symbol"/>{{cartInfo.totalPrice ? `${ $utils.moneyFormat(cartInfo.totalPrice)}` : ``}}</span>
        </button>
      </footer>
    </div>
  </el-drawer>
</template>
<script>
  import service from "@/utils/request";
export default {
  name: 'Cart',
  data() {
    return {
      drawer: false,
      cartNumber: 0,
      cartInfo: {},
      shippingFree: null,
      symbol: null
    }
  },
  computed: {
    cartBlockWidth() {
      const DEFAULT_SIZE = '20rem'
      const SETTING_SIZES = [{
        minWidth: 1400,
        size: '40rem'
      },{
        minWidth: 960,
        size: '30rem'
      }]
      const res = SETTING_SIZES.find(item => document.body.clientWidth > item.minWidth)
      return res&&res.size || DEFAULT_SIZE
    }
  },
  methods: {
    open() {
      this.drawer = true;
      this.getCartInfo();
      //this.getShippingFree();
    },
    getCartInfo() {
      service({
        url: `/car/list`,
        method: 'get',
      }).then(res => {
        this.cartNumber = res.data.count;
        this.cartInfo = res.data;
        this.symbol = res.data.symbol;
        this.shippingFree = res.data.shippingFree
      })
    },
    removeOrderItem(id) {
      service({
        url: `/car/delete/${id}`,
        method: 'delete',
      }).then(res => {
        if (res.code === 200) {
          this.$emit('change');
          this.getCartInfo();
        }
      })
    },
    quantityChange(item) {
      service({
        url: `/car/update?id=${item.id}&count=${item.productCount}`,
        method: 'put'
      }).then(res => {
        if (res.code === 200) {
          this.$emit('change');
          this.getCartInfo();
        }
      })
    },
    checkout() {
      this.drawer = false
      this.$router.push('/checkout').catch(()=>{})
    },
    getShippingFree() {
      this.shippingFree = localStorage.getItem('shippingFree')
    }
  }
}
</script>
<style scoped lang="scss">
.corner {
  position: absolute;
  font-size: 0.75rem;
  z-index: 2;
  font-style: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  color: #fff;
  padding: 0.4rem 0.5rem;
  line-height: 1;
  text-align: center;
}
</style>