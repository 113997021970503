const getters = {
  userInfo: state => state.user.userInfo,
  hasNewCartTip: state => state.cart.hasNewCartTip,
  whatsapp: state => state.user.whatsapp,

  allCurrencies: state => state.user.allCurrencies,
  currencyCode: state => state.user.currencyCode,
  currencySymbol: state => state.user.currencySymbol,
  shippingFree: state => state.user.shippingFree,
};
export default getters;
