const doShowing = (e, el, binding) => {
  // document.addEventListener("click", function (e) {
  const { hiddenId, unTriggerId } = binding.value
  const nodeId = `#${hiddenId}`
  if (el.contains(e.target) && binding.value) {
    if (e.target.id === unTriggerId) {
      el.querySelector(nodeId).style.display = "block"
    } else {
      el.querySelector(nodeId).style.display = "none"
    }
  }
  // })
}

export default {
  bind(el, binding) {
    document.addEventListener("click", function (e) { doShowing(e, el, binding) })
  },
  unbind(el, binding) {
    document.removeEventListener('click', function (e) { doShowing(e, el, binding) });
  }
}
