<template>
  <el-dialog :visible.sync="dialogVisible" width="80%" :fullscreen="isThin">
    <ProductOrder :product-info="productInfo" @addToCartSuccess="addToCartSuccess"></ProductOrder>
  </el-dialog>
</template>
<script>
  import ProductOrder from '@/components/ProductOrder'
  import service from "@/utils/request";
export default {
  name: 'QuickShop',
  components: {ProductOrder},
  props: {
    productId: {
      type: String | Number,
      default: ''
    }
  },
  computed: {
    isThin() {
      const clientWidth = document.body.clientWidth
      return clientWidth <= 576
    }
  },
  data() {
    return {
      dialogVisible: false,
      productInfo: {}
    }
  },
  watch: {
    productId(val) {
      if (val) {
        this.getProductInfo(val)
      }
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    getProductInfo(productId) {
      service({
        url: `/product/${productId}`,
        method: 'get',
      }).then(res => {
        this.productInfo = res.data;
        //console.log(res);
      })
    },
    addToCartSuccess() {
      this.dialogVisible = false;
    }
  }
}
</script>
