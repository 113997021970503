var render = function render(){var _vm=this,_c=_vm._self._c;return (this.collectionsList.length > 0)?_c('div',{staticClass:"MainProduct-page"},[_c('el-row',{staticStyle:{"display":"flex","flex-wrap":"wrap"},attrs:{"gutter":20}},_vm._l((_vm.collectionsList),function(item){return _c('el-col',{key:item.id,attrs:{"span":8,"sm":8,"xs":24}},[(item.url)?_c('div',{staticClass:"collections-box"},[_c('a',{attrs:{"href":item.url,"target":item.url.indexOf('target=self') > -1 ? '_self' : '_blank'}},[_c('img',{attrs:{"src":item.img}}),_c('p',[_vm._v(_vm._s(item.name))]),_c('span',{staticStyle:{"width":"60%"},domProps:{"innerHTML":_vm._s(item.intro)}})])]):(item.productId)?_c('div',{staticClass:"collections-box"},[_c('router-link',{attrs:{"tag":"a","to":{
              name: 'ProductDetail',
              query: {
                type: 'collection',
                productId: item.productId,
                productName: item.productName,
              },
            }}},[_c('img',{attrs:{"src":item.img}}),_c('p',[_vm._v(_vm._s(item.name))]),_c('span',{staticStyle:{"width":"60%"},domProps:{"innerHTML":_vm._s(item.intro)}}),_c('div',[_vm._v("shop now")])])],1):(item.img)?_c('div',{staticClass:"collections-box"},[_c('router-link',{staticClass:"main-prod",attrs:{"tag":"a","to":{
              name: 'ProductList',
              query: {
                type: 'category',
                categoryId: item.id,
                categoryName: item.name,
              },
            }}},[_c('img',{attrs:{"src":item.img}}),_c('p',[_vm._v(_vm._s(item.name))])])],1):_c('div',{staticClass:"collections-box"},[_c('router-link',{attrs:{"tag":"a","to":{
              name: 'ProductList',
              query: {
                type: 'collection',
                collectionId: item.id,
                collectionName: item.name,
              },
            }}},[_c('img',{attrs:{"src":item.img}}),_c('p',[_vm._v(_vm._s(item.name))]),_c('span',{staticStyle:{"width":"60%"},domProps:{"innerHTML":_vm._s(item.intro)}}),_c('div',[_vm._v("shop now")])])],1)])}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }