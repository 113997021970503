export default {
  namespaced: true,
  state: {
    hasNewCartTip: false
  },
  mutations: {
    SET_HAS_NEW_TIP(state, hasNewTip) {
      state.hasNewCartTip = hasNewTip;
    }
  },
  actions: {
    setHasNewTip({commit}, hasNewTip) {
      commit('SET_HAS_NEW_TIP', hasNewTip);
    }
  }
}