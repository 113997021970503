
import request from '@/utils/request'

export const getRecommends = () => {

  return request({
    url: 'open/recommends',
    method: 'get',
  })
}
