
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import CurrencySymbol from './CurrencySymbol'


export default {
  install: (Vue) => {
    Vue.component('ElImageViewer', ElImageViewer);
    Vue.component('CurrencySymbol', CurrencySymbol);
  }
};
