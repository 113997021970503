/* eslint-disable no-param-reassign */
export default {
  namespaced: true,
  state: {
    userInfo: null,
    userId: null,
    whatsapp: null,

    allCurrencies: null,
    currencyCode: "USD",
    currencySymbol: "$",
    shippingFree: '',
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
    },
    SET_WHATS_APP(state, whatsapp) {
      state.whatsapp = whatsapp
    },
    SET_allCurrencies(state, data) {
      state.allCurrencies = data
    },
    SET_currencyCode(state, data) {
      state.currencyCode = data
      localStorage.setItem('currencyCode', data || null)
    },
    SET_currencySymbol(state, data) {
      state.currencySymbol = data
      localStorage.setItem('currencySymbol', data || null)
    },
    SET_shippingFree(state, data) {
      state.shippingFree = data
      localStorage.setItem('shippingFree', data || null)
    },
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      commit('SET_USER_INFO', userInfo);
    },
    setWhatsApp({ commit }, whatsapp) {
      commit('SET_WHATS_APP', whatsapp);
    }
  }
};
