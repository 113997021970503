<comment># 组件注释</comment>

<template>
  <div class="MainProduct-page" v-if="this.collectionsList.length > 0">

    <el-row :gutter="20" style="display: flex; flex-wrap: wrap">
      <el-col
        :span="8"
        :sm="8"
        :xs="24"
        v-for="item in collectionsList"
        :key="item.id">
        <div v-if="item.url" class="collections-box">
          <a :href="item.url" :target="item.url.indexOf('target=self') > -1 ? '_self' : '_blank'">
            <img :src="item.img" />
            <p>{{ item.name }}</p>
            <span style="width: 60%" v-html="item.intro"></span>
            <!-- <div>shop now</div> -->
          </a>
        </div>
        <div v-else-if="item.productId" class="collections-box">
          <router-link
            tag="a"
            :to="{
                name: 'ProductDetail',
                query: {
                  type: 'collection',
                  productId: item.productId,
                  productName: item.productName,
                },
              }">
            <img :src="item.img" />
            <p>{{ item.name }}</p>
            <span style="width: 60%" v-html="item.intro"></span>
            <div>shop now</div>
          </router-link>
        </div>

        <!-- 分类 -->
        <div v-else-if="item.img" class="collections-box">
          <router-link
            class="main-prod"
            tag="a"
            :to="{
                name: 'ProductList',
                query: {
                  type: 'category',
                  categoryId: item.id,
                  categoryName: item.name,
                },
              }">
            <img :src="item.img" />
            <p>{{ item.name }}</p>

          </router-link>
        </div>

        <div v-else class="collections-box">
          <router-link
            tag="a"
            :to="{
                name: 'ProductList',
                query: {
                  type: 'collection',
                  collectionId: item.id,
                  collectionName: item.name,
                },
              }">
            <img :src="item.img" />
            <p>{{ item.name }}</p>
            <span style="width: 60%" v-html="item.intro"></span>
            <div>shop now</div>
          </router-link>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import { getRecommends } from '@/api/home';

  export default {
    name: 'MainProduct',
    data () {
      return {
        collectionsList: [],
      }
    },
    props: {},
    components: {},
    computed: {},
    watch: {},
    created () {},
    mounted () {
      this.getCollections();
    },
    beforeDestroyed () {},
    destroyed () {},
    methods: {

      getCollections() {
        getRecommends({
          url: 'open/collections',
          method: 'get',
        }).then((res) => {
          //console.log(res.data)
          this.collectionsList = res.data
          // this.collectionsInfo = res.data.info
        })
      },

    },
  }
</script>

<style lang="scss" scoped>

  .MainProduct-page {
    margin: 20px ;
  }

  .collections-box {
    /*height: 400px;*/
    position: relative;
    margin-bottom: 5px;
    border-right: 2px solid #fff;

    &:last-of-type {
      border: 0;
    }

    .main-prod {

      span {
        position: initial;
        display: inline-block;
      }

      p {
        color: #333333;
        font-weight: 600;
        font-size: 18px;
        line-height: 4rem;
        text-align: left;
        position: initial;
      }
    }

    span {
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      position: absolute;
      top: 48%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 70%;
    }

    div {
      border: 1px solid #fff;
      width: 150px;
      text-align: center;
      height: 60px;
      line-height: 60px;
      color: #fff;
      position: absolute;
      top: 70%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      font-size: 20px;
      font-weight: 600;
      border-radius: 30px;
      cursor: pointer;
    }

    img {
      display: block;
      width: 100%;
      height: 400px;
    }

    p {
      color: #fff;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      position: absolute;
      top: 40%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }
</style>
