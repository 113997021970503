function moneyFormat(val) {
  if (typeof val !== 'number' && typeof val !== 'string') {
    return 0
  }

  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export default {
  install: (Vue) => {

    Vue.filter('moneyFormat', moneyFormat);
  }
};

