<template>
  <div id="app" data-server-rendered="true">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  created() {
    let session_userInfo = sessionStorage.getItem('userInfo');
    if (session_userInfo) {
      this.$store.commit('user/SET_USER_INFO', JSON.parse(session_userInfo));
    } else {
      let local_userInfo = localStorage.getItem('userInfo')
      if (local_userInfo) {
        this.$store.commit('user/SET_USER_INFO', JSON.parse(local_userInfo));
      }
    }

    this.initCurrency();
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    // document.dispatchEvent(new Event("custom-render-trigger"));
  },
  methods: {
    initCurrency() {

      let shippingFree = localStorage.getItem('shippingFree');
      if (shippingFree) {
        this.$store.commit('user/SET_shippingFree', shippingFree);
      }

      let currencyCode = localStorage.getItem('currencyCode');
      if (currencyCode) {
        this.$store.commit('user/SET_currencyCode', currencyCode);
      }

      let currencySymbol = localStorage.getItem('currencySymbol');
      if (currencySymbol) {
        this.$store.commit('user/SET_currencySymbol', currencySymbol);
      }
    }
  }
}
</script>
