<template>
  <el-drawer
      :visible.sync="globalSearchDrawerVisible"
      direction="ttb"
      class="search-drawer"
      size="20%"
      :with-header="false"
      @close="handleClose"
      v-outer-click="searchModel"
  >
    <div class="offcanvas offcanvas-top" tabindex="-1">
      <div class="search-flyout__wrapper">
        <div class="search-form search-form--flyout">
          <div class="search-form__field-autocomplete">
            <div class="search-form__field">
              <input type="text" placeholder="Search" class="search-form__input" id="search-input" v-model="keyword" autocomplete="off" @input="handleInput" @keyup="handleKeyUp" />
              <button class="search-form__submit-button" type="button" id="search-button" @click='handleSearch'>
                <img src="@/assets/icon/search-icon.svg" alt="">
              </button>
            </div>
          </div>

          <div class="search-form__results-container" style="max-height:500px;overflow-y: auto;" id="searchResults">
            <div id="searchLoading" class="search-loading" style="display: none;">
              Loading
            </div>
            <div class="search-autocomplete" id="searchAutoComplete">
              <ul>
                <li class='autocomplete-item' @click="searchItem(item)" v-for="item in suggestionList" :key="item">
                  {{ item }}
                </li>
              </ul>
            </div>

            <h6 v-show="Array.isArray(searchResultList) && keyword">Products</h6>
            <div class="no-search-result-data" v-if="keyword&& Array.isArray(searchResultList) && searchResultList.length === 0">
              No matching results found.
            </div>
            <div>
              <div id="result">
                <ul>
                  <li class="result-item" v-for="item in searchResultList" :key="item.id"  @click="viewDetail(item)">
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <a class="img__wrap" href="javascript:void(0);">
                          <img :src="item.coverImg" alt="">
                        </a>
                      </el-col>
                      <el-col :span="16">
                        <p>
                          <a href="javascript:void(0);" @click="viewDetail(item)"><strong>{{ item.name }}</strong></a>
                        </p>
                        <p><CurrencySymbol :symbol="item.symbol"/>{{ item.sellingPrice  | moneyFormat}}</p>
                      </el-col>
                    </el-row>
                  </li>
                </ul>
              </div>
              <el-pagination
                  v-if="searchResultTotal"
                  layout="prev, pager, next"
                  :page-size="limit"
                  :total="searchResultTotal">
              </el-pagination>
            </div>
          </div>
        </div>
        <button class="search-flyout__close-button" type="button" @click="globalSearchDrawerVisible = false">Cancel
        </button>
      </div>
    </div>

  </el-drawer>
</template>
<script>
import service from '@/utils/request';

export default {
  name: 'GlobalSearch',
  data() {
    return {
      globalSearchDrawerVisible: false,

      keyword: '',

      suggestionList: [],
      searchResultList: null,
      searchResultTotal: 0,
      limit: 20,
      searchModel: {
        hiddenId: 'searchResults',
        unTriggerId: 'search-input'
      }
    }
  },
  methods: {
    open() {
      this.globalSearchDrawerVisible = true;
    },
    handleInput() {
      Promise.all([this.getSearchSuggestion(), this.globalSearch()]).then(res => {
        this.suggestionList = res[0].data;
        this.searchResultList = res[1].data || [];
      })
    },
    getSearchSuggestion() {
      return new Promise((resolve, reject) => {
        service({
          url: `/es/suggestion`,
          method: 'post',
          data: {
            key: this.keyword
          },
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })
    },
    handleSearch() {
      this.$router.push({
        name: 'SearchResult',
        query: {
          search: this.keyword
        }
      })
      this.globalSearchDrawerVisible = false
    },
    globalSearch(page = 1, limit = this.limit) {
      return new Promise((resolve, reject) => {
        service({
          url: `/es/search`,
          method: 'post',
          data: {
            key: this.keyword,
            page,
            limit
          },
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })
    },
    viewDetail(item) {
      this.globalSearchDrawerVisible = false
      this.$router.push({
        name: 'ProductDetail',
        query: {
          type: 'product',
          productId: item.id,
          productName: item.name
        }
      }).catch(() => {
      })
    },
    searchItem(item) {
      this.keyword = item;
      this.suggestionList = [];
      this.globalSearch().then(res => {
        this.searchResultList = res.data || [];
      });
    },
    handleClose() {
      this.handleReset()
    },
    handleReset() {
      this.keyword = ''
      this.suggestionList = []
      this.searchResultList = []
    },
    handleKeyUp(e) {
      if (e.keyCode === 13) {
        this.handleSearch()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-drawer, .search-drawer >>> .el-drawer {
  overflow: visible;
}

.search-drawer >>> .el-drawer__body {
  overflow: visible;
}
.offcanvas-top {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
