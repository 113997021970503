<template>
  <el-drawer
    :with-header="false"
    :visible.sync="mobileNavDrawerVisible"
    size="20rem"
    direction="ltr"
  >
    <nav class="navigation-mobile" aria-label="Menu">
      <button
        class="navigation-mobile__close-button"
        aria-label="close"
        @click="close"
      >
        <img src="@/assets/icon/close.svg" alt="" />
      </button>
      <div class="navigation-mobile__flyout">
        <div class="navigation-mobile__account">
          <router-link
            tag="a"
            to="/account"
            class="navigation-mobile__account-link"
          >

            <template v-if="isLogin">
              <img class="nav-icon-user" v-if="userInfo.img" :src="userInfo.img" alt="">
              <img v-else class="icon-user" src="@/assets/icon/user-icon.svg" alt="" />

              <span v-if="userInfo.firstName || userInfo.lastName"> {{ userInfo.firstName }} {{ userInfo.lastName }}</span>
              <span v-else>My Account</span>

            </template>

            <template  v-else>
              <img class="icon-user" src="@/assets/icon/user-icon.svg" alt="" />
              <span>Not Sign In</span>
            </template>

          </router-link>
        </div>
        <ul class="mobile-menu">
          <li class="mobile-menu__tier-1-item">
            <router-link
              tag="a"
              to="/home" class="mobile-menu__tier-1-link pxu-lia-element animate-nav-mobile-0"
              >HOME</router-link
            >

            <el-drawer
              :with-header="false"
              :visible.sync="thirdMenuVisible"
              :append-to-body="true"
              direction="ltr"
              size="20rem"
            >
              <div class="mobile-menu__tier-3" tabindex="-1">
                <button
                  class="navigation-mobile__close-button"
                  aria-label="close"
                  @click="close"
                >
                  <img src="@/assets/icon/close.svg" alt="" />
                </button>
                <div class="mobile-menu__tier-3-flyout">
                  <div class="mobile-menu__header">
                    <button
                      class="mobile-menu__back-button"
                      @click="thirdMenuVisible = false"
                    >
                      <img
                        src="@/assets/icon/back.svg"
                        alt=""
                        class="icon-chevron"
                      />
                    </button>
                    <a
                      href="javascript:void(0)"
                      class="mobile-menu__heading"
                    >{{ currentThirdMenu.name }}</a
                    >
                  </div>
                  <ul class="mobile-menu__tier-3-menu">
                    <li
                      class="mobile-menu__tier-3-item"
                      v-for="child in currentThirdMenu.children"
                      :key="child.id"
                      @click="close"
                    >
                      <router-link
                        tag="a"
                        :to="{
                                name: 'ProductList',
                                query: {
                                  type: 'category',
                                  categoryId: child.id,
                                  categoryName: child.name,
                                },
                              }"
                        class="mobile-menu__tier-3-link"
                      >{{ child.name }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </el-drawer>
          </li>

          <li
            class="mobile-menu__tier-2-item"
            v-for="menu in menuList"
            :key="menu.id">
            <button
              class="mobile-menu__tier-2-button"
              @click="openThirdMenu(menu.id)"
              v-if="menu.children.length > 0">
              {{ menu.name }}
              <img
                src="@/assets/icon/arrow.svg"
                alt=""
                class="icon-chevron-small"/>
            </button>
            <router-link
              tag="a"
              :to="{
                name: 'ProductList',
                query: {
                  type: 'category',
                  categoryId: menu.id,
                  categoryName: menu.name,
                },
              }"
              v-else
              class="mobile-menu__tier-3-link"
            >{{ menu.name }}</router-link>
          </li>
          <!--<li class="mobile-menu__tier-1-item">
            <button
              class="mobile-menu__tier-1-button pxu-lia-element animate-nav-mobile-1"
              @click="secondMenuVisible = true"
            >
              Catalog
              <img
                src="@/assets/icon/arrow.svg"
                alt=""
                class="icon-chevron-small"
              />
            </button>

            <el-drawer
              :with-header="false"
              :visible.sync="secondMenuVisible"
              :append-to-body="true"
              direction="ltr"
              size="20rem"
            >
              <div class="mobile-menu__tier-2" tabindex="-1">
                <button
                  class="navigation-mobile__close-button"
                  aria-label="close"
                  @click="close"
                >
                  <img src="@/assets/icon/close.svg" alt="" />
                </button>
                <div class="mobile-menu__tier-2-flyout">
                  <div class="mobile-menu__header">
                    <button
                      class="mobile-menu__back-button"
                      @click="secondMenuVisible = false"
                    >
                      <img
                        src="@/assets/icon/back.svg"
                        alt=""
                        class="icon-chevron"
                      />
                    </button>
                    <a href="javascript:void(0)" class="mobile-menu__heading"
                      >Catalog</a
                    >
                  </div>
                  <ul class="mobile-menu__tier-2-menu">
                    <li
                      class="mobile-menu__tier-2-item"
                      v-for="menu in menuList"
                      :key="menu.id"
                    >
                      <button
                        class="mobile-menu__tier-2-button"
                        @click="openThirdMenu(menu.id)"
                      >
                        {{ menu.name }}
                        <img
                          src="@/assets/icon/arrow.svg"
                          alt=""
                          class="icon-chevron-small"
                        />
                      </button>
                    </li>
                  </ul>
                  <el-drawer
                    :with-header="false"
                    :visible.sync="thirdMenuVisible"
                    :append-to-body="true"
                    direction="ltr"
                    size="60%"
                  >
                    <div class="mobile-menu__tier-3" tabindex="-1">
                      <button
                        class="navigation-mobile__close-button"
                        aria-label="close"
                        @click="close"
                      >
                        <img src="@/assets/icon/close.svg" alt="" />
                      </button>
                      <div class="mobile-menu__tier-3-flyout">
                        <div class="mobile-menu__header">
                          <button
                            class="mobile-menu__back-button"
                            @click="thirdMenuVisible = false"
                          >
                            <img
                              src="@/assets/icon/back.svg"
                              alt=""
                              class="icon-chevron"
                            />
                          </button>
                          <a
                            href="javascript:void(0)"
                            class="mobile-menu__heading"
                            >{{ currentThirdMenu.name }}</a
                          >
                        </div>
                        <ul class="mobile-menu__tier-3-menu">
                          <li
                            class="mobile-menu__tier-3-item"
                            v-for="child in currentThirdMenu.children"
                            :key="child.id"
                            @click="close"
                          >
                            <router-link
                              tag="a"
                              :to="{
                                name: 'ProductList',
                                query: {
                                  type: 'category',
                                  categoryId: child.id,
                                  categoryName: child.name,
                                },
                              }"
                              class="mobile-menu__tier-3-link"
                              >{{ child.name }}</router-link
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </el-drawer>
                </div>
              </div>
            </el-drawer>
          </li>-->
          <li class="mobile-menu__tier-1-item">
            <router-link
              tag="a"
              to="/blog"
              class="mobile-menu__tier-1-link pxu-lia-element animate-nav-mobile-3"
              >BLOG</router-link
            >
          </li>
          <!-- <li class="mobile-menu__tier-1-item">
            <router-link
              tag="a"
              to="/about-us-more"
              class="mobile-menu__tier-1-link pxu-lia-element animate-nav-mobile-2"
              >ABOUT</router-link
            >
          </li> -->
          <li class="mobile-menu__tier-1-item">
            <router-link
              tag="a"
              to="/faq"
              class="mobile-menu__tier-1-link pxu-lia-element animate-nav-mobile-2"
              >FAQ</router-link
            >
          </li>
          <li class="mobile-menu__tier-1-item">
            <router-link
              tag="a"
              to="/contact"
              class="mobile-menu__tier-1-link pxu-lia-element animate-nav-mobile-4"
              >CONTACT</router-link
            >
          </li>
        </ul>
      </div>
    </nav>
  </el-drawer>
</template>
<script>
import service from '@/utils/request'
import { isLogin } from '@/utils/util'

export default {
  name: 'MobileNav',
  data() {
    return {
      mobileNavDrawerVisible: false,
      secondMenuVisible: false,
      thirdMenuVisible: false,
      currentThirdMenu: {
        name: '',
        children: [],
      },

      menuList: [],
      isLogin: false,
    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.userInfo || {};
    },
  },
  watch: {
    $route: {
      handler(val) {
        this.isLogin = isLogin();
        this.mobileNavDrawerVisible = false
      },
    },
  },
  created() {
    this.isLogin = isLogin();
    this.getMenuList()
  },
  methods: {
    open() {
      this.mobileNavDrawerVisible = true
    },
    close() {
      this.thirdMenuVisible = false
      this.secondMenuVisible = false
      this.mobileNavDrawerVisible = false
    },

    getMenuList() {
      service({
        url: '/category/list',
        method: 'get',
      }).then((res) => {
        this.menuList = res.data
      })
    },

    openThirdMenu(id) {
      this.currentThirdMenu = this.menuList.filter((item) => item.id === id)[0]
      this.thirdMenuVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.el-drawer__wrapper {
  padding-right: 3.5rem;
}
::v-deep .el-drawer__open .el-drawer.rtl {
  animation: rtl-drawer-in 0.5s 1ms;
}
::v-deep .el-drawer__open .el-drawer.ltr {
  animation: ltr-drawer-in 0.5s 1ms;
}
</style>
