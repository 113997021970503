export default {
  bind(el, bindings) {
    el.onmouseenter = () => {
      el.querySelector('.shop_link').style.display = document.body.clientWidth > 576 ? "block" : "none";
    }
    el.onmouseleave = () => {
      el.querySelector('.shop_link').style.display = "none";
    }
  }
}
