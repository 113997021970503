<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer-blocks">
        <div class="footer-block">
          <!-- <h2 class="footer-block__title">15% off your first order</h2>
          <form action="" class="footer-newsletter">
            <div class="footer-newsletter__caption">
              <p>Subscribe today and save</p>
            </div>
            <div class="footer-newsletter__wrapper">
              <input
                type="email"
                placeholder="your@email.com"
                v-model="email"
                class="footer-newsletter__email"
              />
              <button
                class="footer-newsletter__submit"
                type="button"
                @click="subscribe()"
              >
                <img
                  src="@/assets/icon/button-enter.svg"
                  alt=""
                  class="icon-arrow-right"
                />
              </button>
            </div>
          </form> -->
          <div class="article-page__share">
            <h5 class="article-page__share-text">Connect with us</h5>
            <div class="contentIcon">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAqklEQVR4nO2WwQ6AIAxD+f+frhcPxigELG6UvoSDMzF5ssJKMesB0goHaiKjWOQr7NYIazWoiczCIqw/dq/3PrfqdORFWFgk645MH3nwU0ZkREaBRSbvCOu74cevjEgvcEbUMgKLJMsIC1kROCMNPGuVzYdGqIwoUBGRaS0ZEfge2b21WMiKIDoj11rt3TYivcAiJ26tbBl5opaRN8IzsqwIkq8mMiKmBHIAGcKdfwldIgoAAAAASUVORK5CYII="
              />
              <span>Boyue Trading Co., Ltd.</span>
            </div>
            <div class="contentIcon">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADlUlEQVR4nO2YTUgVURTH/5op5WdRZErYpqSUonLTJ7V+lfa5qJ2rItr5fLQpSjOCIAyhhD72GURFm8zCImhnC8U+oLKEvqW0sojeixP/gdvjzcydefPuDOEPLgzv3PO/d94999w7B5ji/6cWQALAbQDDAL6yyXMvgFYAixFhGgD0AUhpNnnRVYgQ0wF0AUhygp8AnAewlatTzCbPjQAusE+KPmcAFIT9ErMA3OGkvgFoA1Cm4Sd92gF8V1anAiGuhBVKowwtrywH8JIa/QAKEQJdnMArAFVZ6Ijva2p1wjANjG8JpxUB6K1kmP3mszGskJI9ERTHqSkp2gi1SnZy2tib+cJyhkzwOebQvxzAGLUXwQAJDiZp1I52h/PDaRUvsU8cBujlYHIm2K2E2H8AaAEwny3O31IOK7ON9lswwBMOZnfNsM4VeYl0WmmTMHMK28cwwAQHK3GxV2awVdI2buNbQrto5BzrNJ5hYx+nXcIpnWravtj4Fiu3hJwzysFqXFJz3CFR2IXWQtrlgMw5/S4bNqZs9lae3FV8iZ8uvltovwsDdHIwSbF2tPlMvx3scxoG2MHBhlz6xRhCE5oHojBMbUnDOadMCZH6AHWXUVO0S2GImxz0aICax6h5AwbZzUGfAsgPQE80nlFzFwxSBOAtB5ZP2mxppNYbahvlEAe/H4DWA2olEAKy6T9zAquz0FmrnPYVCIlTnMTVLDSuU+MkQqSady/57F3jw3+dcrfKdDczivWJ+hBAns+9Iak3dEqZbWRC2z347aTPO81amBH2K+eKTvosYl/x2YcIUQBg0EOYWJfKQRPl0jwAz10K0RvTNm6Sd6U6B9169pG+m9LScNJhrBEfe/Av6zUq6un/6EVl40+zuYpYG1wK3RbS95HGeDInz5yjs2QlXWYDeE+/AxnsB2n7CGCOB90O+smcPFGolP6dwiQTe5WTeoHye43yPb/Ho2adUhT0VOhuouMA/HGF/vcYNvlKmeiaT80Bl3paRnocalM6zOX5IBqHARzh8wcA83xqtlDjsq6D1GAnWRmXK4hfrKrhL7ZsP2GrOadJztGVZpeSjZ+EIe1sAHp91GoOvLMLM1mkGOJztjTr/smel0+DJWxBUK4b9nG+sWz2qNKjk4is01XSb1RpcjsalrLDWBgFAA8U8mZgW1c7QWM3ok835ypXl3+QW+ULjUtb1NpIel1tQwQm5bfJ3KdA1PgDYpWe90NC9SoAAAAASUVORK5CYII="
              />
              <span>Office address: <br/>42b Rutland Street, Auckland Central, Auckland 1010, New Zealand</span>
            </div>
            <div class="contentIcon">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADlUlEQVR4nO2YTUgVURTH/5op5WdRZErYpqSUonLTJ7V+lfa5qJ2rItr5fLQpSjOCIAyhhD72GURFm8zCImhnC8U+oLKEvqW0sojeixP/gdvjzcydefPuDOEPLgzv3PO/d94999w7B5ji/6cWQALAbQDDAL6yyXMvgFYAixFhGgD0AUhpNnnRVYgQ0wF0AUhygp8AnAewlatTzCbPjQAusE+KPmcAFIT9ErMA3OGkvgFoA1Cm4Sd92gF8V1anAiGuhBVKowwtrywH8JIa/QAKEQJdnMArAFVZ6Ijva2p1wjANjG8JpxUB6K1kmP3mszGskJI9ERTHqSkp2gi1SnZy2tib+cJyhkzwOebQvxzAGLUXwQAJDiZp1I52h/PDaRUvsU8cBujlYHIm2K2E2H8AaAEwny3O31IOK7ON9lswwBMOZnfNsM4VeYl0WmmTMHMK28cwwAQHK3GxV2awVdI2buNbQrto5BzrNJ5hYx+nXcIpnWravtj4Fiu3hJwzysFqXFJz3CFR2IXWQtrlgMw5/S4bNqZs9lae3FV8iZ8uvltovwsDdHIwSbF2tPlMvx3scxoG2MHBhlz6xRhCE5oHojBMbUnDOadMCZH6AHWXUVO0S2GImxz0aICax6h5AwbZzUGfAsgPQE80nlFzFwxSBOAtB5ZP2mxppNYbahvlEAe/H4DWA2olEAKy6T9zAquz0FmrnPYVCIlTnMTVLDSuU+MkQqSady/57F3jw3+dcrfKdDczivWJ+hBAns+9Iak3dEqZbWRC2z347aTPO81amBH2K+eKTvosYl/x2YcIUQBg0EOYWJfKQRPl0jwAz10K0RvTNm6Sd6U6B9169pG+m9LScNJhrBEfe/Av6zUq6un/6EVl40+zuYpYG1wK3RbS95HGeDInz5yjs2QlXWYDeE+/AxnsB2n7CGCOB90O+smcPFGolP6dwiQTe5WTeoHye43yPb/Ho2adUhT0VOhuouMA/HGF/vcYNvlKmeiaT80Bl3paRnocalM6zOX5IBqHARzh8wcA83xqtlDjsq6D1GAnWRmXK4hfrKrhL7ZsP2GrOadJztGVZpeSjZ+EIe1sAHp91GoOvLMLM1mkGOJztjTr/smel0+DJWxBUK4b9nG+sWz2qNKjk4is01XSb1RpcjsalrLDWBgFAA8U8mZgW1c7QWM3ok835ypXl3+QW+ULjUtb1NpIel1tQwQm5bfJ3KdA1PgDYpWe90NC9SoAAAAASUVORK5CYII="
              />
              <span>Factory address: <br/>No. 8, North of West Jiang Industrial Zone, Changjiang Road, Sanshan, Zhongqu Community, Guicheng Street, Nanhai District, Foshan, Guangdong, China</span>
            </div>
            <!-- <div class="contentIcon">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADS0lEQVR4nO2ZS0hUURjHf6ZZGJWUVqYWGbQSMlpkRYtE2oRBRAVFmGIYZLWM1tKqwih6CLWpXUXagx4LSVr0EiwhIm3Rg4qg1J5oz4lD/wuHYebOXBnvnIH5wd3M/c7c79x7vv/5vu9AlixpZR3QA4wCfcByMox84DgQibqGgVIyhOlAlxwfAVqAIuCyfrtOBjAHeCyH3wJLo+591L1GHGYa0CtHnwLzY9hs1f1PwDwcZBJwR04+AWb62Ha4vMQOyLlXQFkCW7PEBmVfj2MMybHVSdpvk70ZV4JDjMqxwgBjrmmMWWrO8FxOVQYYU6agN+M24wgdcsioUhB2aFw/jtAih84GHFekcb+BHBxgIfAX+AJMDTBuuyZi8jFn6JJTO5O0nwy8dFGGN1nrPS8J+/2yfwRMwCFylZoY55oT2M4CPsu2FgfZIOfeAQU+du2yMxmxk+QAD+TkQZ/Y+CqbJhxmCfAT+AOsimOzxyq0FuAwrVbgF8T5cldl8zDBMkx7qdsnR03JG4tCSxw6JRZOUqVS1zja4LORfpBNuyu7eywarNp9WUyL/50VL/jbcJijliTH66DUWF/vMI4yEbgtJ+/6BHad1C6iitNJioEXcvKGxCAWG63JtLkaM4uA93LyvI9K1VnL7JSrarZYm6Bx8rTPG6+xBKAzznLMlZiYpNNsvgNKXENjBfAticCutqS5J6pPVmvtU9HXRWA2IbEG+GFNJt6XqbA2zSGVxl5GEFHcmVqmSPe8jNp0NLeENZn11mTO+MSCyQCuRL114/A+JaA25RITz65DfbRQvoy3zC74qJn5YrsVNydVz/jRaMXioPpo485K66E3EySQpjWbLKVW7yyiJTmXEPIyT5rvpbj7WG91Qod9UqWU7jNeM+JNih9YYomEketQMoBuPXDUJ2seCzP0v98JMTc7Zq3tEykqvPZa+V6oNFjN8QGJwliptqR+LWmgytrBTRpyCJgS8D8qLCE5QhrJVw/gl1XXNCfZBCwGnmncLS3btGMOVu9bsdOv9my8XnO5ld70BuxJjzs5agJ6bzmixvk5HWlU6njcZM+vLbkNLYkMSp4Oh7p0ChCJc3VrYhmBCeZdwCWp24iy39aAKU2WLKSQf3jSBy3qRwNwAAAAAElFTkSuQmCC"
              />
              <span>+86 18607993853</span>
            </div> -->
            <div class="contentIcon">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABmklEQVR4nO2XTSgFURTHf4N6RZ4kWWCjXikLZaEsKLKhLG0tbe3YWtrJ0u5laSsrWVgoC0UppZR6KSWpp/R44mrqTN3GR/Pmfcyd6fzqLua9c++c/5x7zzkXFEVRlIQwGRlkTkhaMSrEMUzmI+LhPl4UIefADO4yCZxGERI8HwAjuMMwsAd8/eHvDyHbQFV+qwBbQJ7kyIsPFfGpKj5GOuwFYN/67wlYA9pbKKANWAEeLD+OgLE4WWsWuLBsroHF5mtgDrgMvXeh3vQb9cs0gkKMnVBzHekCNkN7dRfob4CAXjkHb7L2O7AD9DSzIA6FssczsAHkYgjoAFaBxzqypam3sgf5PJh/AyzXMH8euLLm+/VrOqkWxRPn76x1joHxf+aMAoeW/b1ExT+LifdanbK9XmStT9l+A5ZNn+z7D7F5lXPR7WLTOAgURYi/ZhlYl1G2RBbF1vnudwI4+eUWdwZMpbGNXwJugZLUIi/N95FczNQclexfrNKGUSGOYTQijmE0Io5hNCKOYTQirkfEpHyQGSGKoigKreYb+HOpsFE9vjIAAAAASUVORK5CYII="
              />
              <a href="mailto:customerservice@abigbigbag.com" class="cursor-pointer text-underline">
                <span>customerservice@abigbigbag.com</span>
              </a>
            </div>
            <div class="contentIcon learn-more" v-if="flag">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFCUlEQVR4nO2aW4hVZRTHf45Tjs5QzdGxC3Q1uxM9ZBlF9SCWlTUNJRgKFUoXUcMub009lNEFAkmSoJfsZvVQhkWWmmGWWqmFlbeITLEaungbR80Ti/4fLM7Ze5999jl7ToR/2HD4vrXXd1vfWv+19oEj+P+iAHQCTwOLgO+B34E+Pfb7O/WZzE1AO/8RtACTgcXA30CxyucQ8AEwCRjUiAUMBu4HdrhJ7QeWAg/rZM7Vjh+lp11t1tcNLNM74f3twCxtTr/gemCrm8AaYApwbAZdxwFTgS+cvi3AOHKE7dQ8N+CXwNg66r8WWOv0z83jdE7QxG2AvcB0YGC9B+FfnTOBfe60j6+X8tN13EV5nQvIHxcCGzXmZs2hJnQ4hauBYfQf2oEVGnurrCITWpw5rQRa6X+0Ap87M8t0Z+Y5c7Jg1ygMdVZhDqBqFxsudn/ciTR3Zp/mZN4tdbALccK8UxwsuH2oxZrsj8BV5If73OVPZWIPuDgR52JHAr9FUI7t2og80Ays0zi2qES0ONoRF+xscask866L6KvVdif5YZzG+LkSN5vkPEQcbpSMsdshrn2y2o1u5IUBzpPeliS4WELGneKwUDJ3RJzmr+q7lPxwl8Z4P06gIFq9vwIB3ClFp0X0PaG++eQbKPuAg3Hz7NQkjIon4YDkoi71KdqM3bqceeFjzcHMvAzPqNPyiST0SO6siL6x6vsjJ1IZ8KjGeYoILFKnpaBJWCo5u9weZ7tFWoKUJ252XrMMm9RpgS4J0yX3Scxxv53zaRjOd56zDGE3jdskwS7YHsmOce0hy7uX/DFMY1lQLkOfOo9OoehxyW5ydOFWte0CziBfDHJ1gjL0JnijUgxxydazrn2B2r4BjqFBC9mhzpNSKrtErvgwMEFtNvlvpeezCvFolBxLW71Na4M67SKlxUy9YxR7tNpOBX5Q+8aYNGCKNqCowp0F0hPrddkD9eiiOjyv934RKw6Bca1bZLfLMK92J7nGMWcz7bkp8/Pgfm3OZehWp+1ONWgW7wk0/hy12117we18j3QH+j9bchcDb7pK5SHgoVoC4nUpKUoUbLeXuAnbrgdcAXxakrdYIGsq0WHx62X1m3tPwnLJjY+bzB6RseEZFmOe7B0NcEC76gPj5aoDPFZC/z2G6/0/E8YpONIY6xlfl6JpZEOTjjuY0wqZTlp06T1LJ+Jwt2TeI0XStE5JTFZcA2yTrsOiLWMizMnjTFcreDBGxub0lWQmJk2gycWBar1XVIF6tih90aWoTwJXigq1aQF2ef9ym9haobqzLc1niJDurqc+6FBqEEhp0vNGgt03a04mNyPNwCdLeHcFU8iCy4A5wNcqI+1SGelVnVISZrkgm+qj0O1JwaZBuMhxwdSfMmr1XPVGh4pyRZ1mKgx0eckIGo82V0NbVc13xtGuLNloFBwj2FLtB59H9OJzNP5ObHbJW1TpKREr9fINNAbN8k69zpyqpku+QBcXkEaotvuSonVnjQwgYICC3XoXU+Zk/fY+QQo+cm0jlQTNd5Sj9LG09p6MRLMg7hRoR1GmVNPX4helyOj4K8otSidttd23VBKa6uhMyCOWiZbcApynrK9FBY2hyha7dBeXu4JHoB0z6vEPiJ8iJr5TtGGa0stSM2pSTrDAlYiqeQ6qMDixnn/hWKJTeE2mUqlQV4rBsvNu3Z8NKmj0aud7dIILRfXH51xpOQIaiX8Aj4PBez3GiikAAAAASUVORK5CYII=">
              <span @click="whatsappShare">Don' t find what you want? Please tell me</span>
            </div>
            <div class="share-buttons">
              <a
                href="https://www.instagram.com/BigBigBag"
                target="_blank"
                class="share-buttons__instagram"
              >
                <svg
                  class="icon-twitter"
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="currentColor"
                    d="M10 1.933c2.629 0 2.938.012 3.977.06.96.042 1.48.202 1.828.339.46.18.789.39 1.133.734.343.344.558.672.734 1.133.133.348.297.867.34 1.828.047 1.04.058 1.348.058 3.977 0 2.629-.011 2.937-.058 3.976-.043.961-.203 1.48-.34 1.829-.18.46-.39.789-.735 1.132a3.006 3.006 0 01-1.132.735c-.348.133-.867.297-1.828.34-1.04.046-1.348.058-3.977.058-2.629 0-2.938-.012-3.977-.059-.96-.042-1.48-.203-1.828-.34-.46-.179-.789-.39-1.133-.734a3.006 3.006 0 01-.734-1.132c-.133-.348-.297-.868-.34-1.829-.047-1.039-.058-1.347-.058-3.976 0-2.63.011-2.938.058-3.977.043-.96.203-1.48.34-1.828.18-.46.39-.789.735-1.133a3.006 3.006 0 011.132-.734c.348-.133.867-.297 1.828-.34 1.04-.05 1.348-.058 3.977-.058zM10 .16C7.328.16 6.992.172 5.941.22 4.895.266 4.18.434 3.555.676c-.649.25-1.196.59-1.743 1.136A4.88 4.88 0 00.677 3.555c-.242.625-.41 1.34-.457 2.39C.172 6.992.16 7.328.16 10c0 2.672.012 3.008.059 4.059.047 1.046.215 1.761.457 2.39.25.649.59 1.195 1.136 1.742a4.88 4.88 0 001.743 1.137c.625.242 1.34.41 2.39.457 1.051.047 1.383.059 4.059.059s3.008-.012 4.059-.059c1.046-.047 1.761-.215 2.39-.457a4.796 4.796 0 001.742-1.137 4.88 4.88 0 001.137-1.742c.242-.625.41-1.34.457-2.39.047-1.051.059-1.383.059-4.06 0-2.675-.012-3.007-.059-4.058-.047-1.047-.215-1.761-.457-2.39a4.796 4.796 0 00-1.137-1.743A4.88 4.88 0 0016.453.673c-.625-.242-1.34-.41-2.39-.457C13.008.172 12.671.16 10 .16z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M10 4.945a5.055 5.055 0 000 10.11A5.058 5.058 0 0015.055 10 5.058 5.058 0 0010 4.945zm0 8.336a3.28 3.28 0 110-6.562 3.28 3.28 0 010 6.562zM15.254 5.926a1.18 1.18 0 100-2.36 1.18 1.18 0 000 2.36z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/BigBigBag"
                target="_blank"
                class="share-buttons__facebook"
              >
                <svg
                  class="icon-facebook"
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 9 20"
                >
                  <path
                    fill="currentColor"
                    d="M8.3 6.309H5.533V4.43c0-.691.494-.89.79-.89H8.3V.38H5.533C2.47.38 1.78 2.751 1.78 4.234v2.075H0v3.26h1.779v9.19h3.754v-9.19h2.47L8.3 6.31z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.twitter.com/BigBigBag"
                target="_blank"
                class="share-buttons__twitter"
              >
                <svg
                  class="icon-twitter"
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="currentColor"
                    d="M20 3.895a8.474 8.474 0 01-2.034 2.084c0 .055.007.142.021.26.014.118.02.205.02.261 0 1.083-.162 2.167-.487 3.25a11.413 11.413 0 01-1.483 3.105c-.663.986-1.45 1.86-2.362 2.624-.912.765-2.006 1.376-3.284 1.834-1.277.458-2.65.687-4.12.687-2.288 0-4.379-.604-6.271-1.813.396.028.72.042.974.042 1.907 0 3.61-.57 5.107-1.709a3.991 3.991 0 01-2.384-.801 4.139 4.139 0 01-1.472-2.01c.24.056.501.083.784.083.381 0 .741-.055 1.08-.166a4.066 4.066 0 01-2.352-1.396 3.825 3.825 0 01-.932-2.542v-.042c.536.278 1.15.431 1.843.458a3.956 3.956 0 01-1.313-1.427A3.904 3.904 0 01.847 4.77c0-.695.177-1.375.53-2.042a11.255 11.255 0 003.739 2.99c1.462.73 3.047 1.135 4.756 1.219a3.395 3.395 0 01-.15-.918c0-1.11.403-2.059 1.208-2.843C11.735 2.392 12.716 2 13.875 2c.565 0 1.108.111 1.631.333.523.223.968.535 1.335.938.932-.18 1.801-.507 2.606-.98-.297.945-.897 1.688-1.801 2.23a8.706 8.706 0 002.352-.625L20 3.895z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.pinterest.com/BigBigBag"
                target="_blank"
                class="share-buttons__pinterest"
              >
                <svg
                  class="icon-pinterest"
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="currentColor"
                    d="M9.958.811c1.268 0 2.48.248 3.635.744a9.448 9.448 0 012.988 2 9.448 9.448 0 012 2.988 9.115 9.115 0 01.744 3.635 9.175 9.175 0 01-1.256 4.696 9.291 9.291 0 01-3.415 3.415 9.176 9.176 0 01-4.696 1.256 9.548 9.548 0 01-2.66-.366c.472-.765.79-1.44.952-2.025l.659-2.561c.163.309.464.581.903.817.44.236.902.354 1.39.354.976 0 1.85-.28 2.622-.842.772-.561 1.37-1.33 1.793-2.305.423-.975.634-2.073.634-3.293 0-1.447-.557-2.704-1.671-3.769-1.114-1.065-2.533-1.598-4.257-1.598-1.057 0-2.025.179-2.903.537-.878.358-1.585.826-2.122 1.403a6.415 6.415 0 00-1.232 1.878 5.2 5.2 0 00-.427 2.037c0 .845.159 1.585.476 2.22a2.703 2.703 0 001.427 1.342c.114.049.212.053.293.012.081-.04.138-.118.17-.232.115-.407.18-.659.196-.756.065-.179.025-.35-.122-.512-.423-.471-.634-1.081-.634-1.83 0-1.236.427-2.297 1.28-3.183.855-.886 1.973-1.329 3.355-1.329 1.22 0 2.171.333 2.854 1 .683.667 1.024 1.537 1.025 2.61 0 .895-.122 1.72-.366 2.476s-.594 1.362-1.05 1.817c-.454.455-.975.683-1.56.683-.488 0-.886-.179-1.195-.537-.309-.358-.406-.781-.293-1.269.065-.228.15-.52.256-.878.105-.357.195-.662.268-.915.073-.253.142-.525.207-.817a3.55 3.55 0 00.098-.732c0-.407-.106-.74-.317-1-.211-.26-.52-.39-.927-.39-.504 0-.927.232-1.27.695-.341.463-.512 1.045-.511 1.744 0 .26.02.512.06.756.042.244.086.423.135.537l.073.171c-.667 2.895-1.073 4.594-1.22 5.098-.13.618-.179 1.342-.146 2.171a9.417 9.417 0 01-4.062-3.44c-1.033-1.545-1.55-3.261-1.55-5.147 0-2.586.916-4.794 2.745-6.623C5.164 1.724 7.37.81 9.957.81v.001z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="footer-block footer-information">
          <h2 class="footer-block__title">INFORMATION</h2>
          <ul class="footer-navigation">
            <li class="footer-navigation__item">
              <div class="footer-navigation__link" @click="routerTo('/faq')">
                FAQ
              </div>
            </li>
            <li class="footer-navigation__item">
              <div
                class="footer-navigation__link"
                @click="routerTo('/shipping')"
              >
                Shipping
              </div>
            </li>
            <li class="footer-navigation__item">
              <div
                class="footer-navigation__link"
                @click="routerTo('/returns')"
              >
                Returns
              </div>
            </li>
            <li class="footer-navigation__item">
              <div
                class="footer-navigation__link"
                @click="routerTo('/privacy-policy')"
              >
                Privacy policy
              </div>
            </li>
            <li class="footer-navigation__item">
              <div
                class="footer-navigation__link"
                @click="routerTo('/terms-of-use')"
              >
                Terms of use
              </div>
            </li>
            <li class="footer-navigation__item">
              <div
                class="footer-navigation__link"
                @click="routerTo('/track-my-order')"
              >
                Track my order
              </div>
            </li>
            <li class="footer-navigation__item">
              <div
                class="footer-navigation__link"
                @click="routerTo('/contact')"
              >
                Contact us
              </div>
            </li>
          </ul>
        </div>
        <div class="footer-block">
          <h2 class="footer-block__title">ABOUT US</h2>
          <div class="footer-text">
            <div>
              Welcome to our world of fashionistas!
              <br><br>
              We are an online store with a physical factory, specializing in the production and sale of high-end fashion leather goods. 
              Our artisans have over a decade of refined craftsmanship and have previously provided subcontracting services for a leading 
              international brand, ensuring the exceptional quality and highly competitive pricing of our products.
              <br><br>
              We are dedicated to offering top-notch fashion products at affordable prices, allowing everyone to enjoy luxurious taste. 
              Our carefully selected range of trendy products at affordable prices will help you radiate unique charm in your exquisite 
              lifestyle. 
              <!-- Welcome to our online store! We understand that fashion is an attitude, a way to express individuality and personal style. That’s why we are dedicated to offering a diverse range of trendy products to meet the needs and tastes of everyone.
              <br><br>
              Our team keeps a passionate eye on fashion trends and constantly updates our product inventory to ensure you can always find the latest and unique fashionable items. Whether you’re a fashion novice or a trendsetter, we have the perfect choices for you.
              <br><br>
              Discover the world of fashion with us! -->
            </div>
            <!-- <div class="learn-more">
              <div @click="routerTo('/about-us-more')">Learn More</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="footer-navigation-attribution">
        <p class="footer-attribution">
          &COPY; 2024 Editions BigBigBag. Powered by Boyue Trading Co., Ltd.
        </p>
      </div>
      <div class="footer-crossborder-payment">
        <div class="footer-cross-border">
          <div class="shopify-cross-border"></div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import service from '@/utils/request'
import mix from '@/views/footerPage/mix'

export default {
  name: 'FooterBar',
  mixins: [mix],
  data() {
    return {
      email: '',
    }
  },
  computed: {
    flag() {
      return this.$store.state.user.whatsapp
    },
  },
  methods: {
    subscribe() {
      if (!this.email) {
        // this.$message.warning('Please input an email!');
        this.$notify({
          title: 'Tip',
          message: 'Please input an email!',
          type: 'warning',
        })
        return
      }
      this.$router.push({
        path: '/join-back',
        query: {
          email: this.email,
        },
      })
      /*service({
        url: '/open/subscriber',
        method: 'put',
        params: {
          email: this.email,
        },
      }).then((res) => {
        if (res.code === 200) {
          this.$notify({
            title: 'Success',
            message: 'Subscribe success!',
            type: 'success',
          })
          // this.$message.success('Subscribe success!')
        } else {
          // this.$message.error(res.msg)
          this.$notify({
            title: 'Error',
            message: res.msg,
            type: 'error',
          })
        }
      })*/
    },
    whatsappShare() {
      window.open(this.$baseUrl + '/open/waMe')
    },
  },
}
</script>
<style scoped>

.learn-more {
  cursor: pointer;
  text-decoration: underline;
}
.footer-icon-media {
  margin-top: 50px;
}
.article-page__share {
  margin-bottom: 1.5rem;
  text-align: left;
}
.article-page__share-text {
  margin-bottom: 1.5rem;
  /* margin-top: 5rem; */
}
.share-buttons {
  display: flex;
}

.share-buttons__instagram,
.share-buttons__twitter,
.share-buttons__facebook,
.share-buttons__pinterest {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  transition: border-color 0.2s ease-out,
    box-shadow 225ms cubic-bezier(0.5, 0, 0.5, 1), color 0.2s ease-out;

  font-style: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 0.5rem 0;
  padding-right: 2rem;
  color: #131416;
  background-color: transparent;
  /* border: 1px solid rgba(19, 20, 22, 0.3); */
  display: flex;
  align-items: center;
  /* margin-right: 0.5rem; */
}

.share-buttons__instagram svg,
.share-buttons__twitter svg,
.share-buttons__facebook svg,
.share-buttons__pinterest svg {
  width: auto;
  height: 1.5rem;
  /* margin-right: 0.5rem; */
}
.contentIcon {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.contentIcon img {
  width: 24px;
  height: 24px;
}
.contentIcon span {
  margin-left: 10px;
}
</style>

